import {IOwner} from '../redux/owner/ownerInterfaces'
import saveupClient, {handleResponse} from '../clients/saveupClient'
import {ILogin, IAuth} from '../redux/login/loginInterfaces'
import {CreateUserParameter, IUser, UpdateUserParameter, UserAttribute, UserListItem, UserOwnerParameter, UserRole} from '../redux/user/interfaces'
import { AxiosResponse } from 'axios'
const userService = {
  login,
  logout,
  search,
  getUsers,
  getUserById,
  getUsersListIitems,
  getCurrentUser,
  getCurrentUserOwners,
  getUserRoles,
  getUserAttributes,
  getOwnersByUserId,
  createUser,
  createUserAttribute,
  createUserOwner,
  updateUser,
  updateUserAttribute,
  updateUserOwners,
  updateUserPassword,
  deleteUser,
  updateUserState,
}

async function login(data: ILogin) {
 return await saveupClient.get<AxiosResponse>(`/User/Login?username=${data.username}&password=${data.password}`)
}
async function search(keyword: string) {
  return await saveupClient.get<IUser[]>(`user/search?keyword=${keyword}`).then(handleResponse)
}
function logout() {
  localStorage.removeItem('auth')
}

async function getCurrentUser() {
  return await saveupClient.get<IUser>('user/current').then(handleResponse)
}

async function getCurrentUserOwners() {
  return await saveupClient.get<IOwner>(`user/owner`).then(handleResponse)
}

async function getUsers() {
  return await saveupClient.get<IUser[]>(`user`).then(handleResponse)
}

async function getUsersListIitems() {
  return await saveupClient.get<UserListItem[]>(`user/attribute/list`).then(handleResponse)
}

async function getUserById(id: number) {
  return await saveupClient.get<IUser>(`user/${id}`).then(handleResponse)
}

async function getUserAttributes(userId: number) {
  return await saveupClient.get<UserAttribute[]>(`user/${userId}/attribute`).then(handleResponse)
}

async function getUserRoles() {
  return await saveupClient.get<UserRole[]>(`userrole`).then(handleResponse)
}

async function getOwnersByUserId(id: number) {
  return await saveupClient.get<IOwner[]>(`user/${id}/owner`).then(handleResponse)
}

async function createUser(user: CreateUserParameter) {
  return await saveupClient.post<IUser>('user', user).then(handleResponse)
}

async function updateUser(user: UpdateUserParameter) {
  return await saveupClient.put<IUser>('user', user).then(handleResponse)
}

async function updateUserAttribute(userId: number, attributeId: number, value: string) {
  return await saveupClient.put(`user/attribute`, {userId: userId, attributeId: attributeId, value: value}).then(handleResponse)
}

async function deleteUser(id: number) {
  return await saveupClient.delete(`user/${id}`).then(handleResponse)
}

async function updateUserState(userId: number, isDisabled: boolean) {
  return await saveupClient.post(`user/state`, {userId, isDisabled}).then(handleResponse)
}

async function createUserAttribute(userId: number, attributeId: number, value: string) {
  return await saveupClient.post(`user/attribute`, {userId: userId, attributeId: attributeId, value: value}).then(handleResponse)
}
async function createUserOwner(userId: number, ownerId: number) {
  return await saveupClient.post(`user/owner`, {userId: userId, ownerId: ownerId}).then(handleResponse)
}

async function updateUserOwners(userId: number, owners: number[]) {
  return await saveupClient.put(`user/owner`, {userId: userId, ownerIds: owners}).then(handleResponse)
}

async function updateUserPassword(userId: number, password: string) {
  return await saveupClient.post<string>(`user/forceresetpassword`, {userId: userId, newPassword: password}).then(handleResponse)
}

export default userService
