import React from 'react'
export enum Role {
  Readers = 0,
  Customer = 1,
  Controllers = 2,
  Administrators = 3,
}
export enum AADRole {
    "d8e1f8f5-59b1-4cbf-8d25-0e2e5725cb85" = 0, // RBAC_ovrig-anställd
    "3524073e-c370-40c9-a2dc-6907fef27625" = 1 // RBAC_energi_controller
//   'customer' = 0,
//   'RBAC_ovrig-anställd' = 1,
//   'RBAC_energi_controller' = 2,
//   'RBAC_forvaltare' = 3,
//   'RBAC_platschef' = 4,
//   'RBAC_kundansvarig' = 5,
//   'RBAC_Front-office' = 6,
}
