import React, {FC, useEffect, useState} from 'react'
import {useForm, SubmitHandler} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {ILogin} from '../../redux/login/loginInterfaces'
import {Button, TextField} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux'
import {login} from '../../redux/login/loginActions'
import './Login.scss'
import {CloudCircle} from '@mui/icons-material'
import {useMsal, useIsAuthenticated} from '@azure/msal-react'
import {loginRequest} from '../auth/msalConfig'
import { InteractionStatus } from '@azure/msal-browser'
import { AADRole } from '../../shared/enums/Role'
const LoginForm: FC = (): any => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {instance, accounts, inProgress} = useMsal()
  const {register, handleSubmit} = useForm<ILogin>()
  const [attempts, setAttempts] = useState<number>(0)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [useAzureAuth, setUseAzureAuth] = useState<boolean>(true)
  
  // const activeAccount = instance.getActiveAccount()
  const isAuthenticated = useIsAuthenticated()
  
  interface JwtPayload {
    exp: number
    [key: string]: any
  }

  useEffect (() => setUseAzureAuth(true), [])

  useEffect(() => {
    if(!useAzureAuth) return
    const activeAccount = accounts[0]
    if (isAuthenticated && inProgress === InteractionStatus.None && activeAccount) {

      const auth = localStorage.getItem('auth')
      const tokenAlreadyAquired = auth !== null && JSON.parse(auth).token !== '' && JSON.parse(auth).token !== undefined && new Date(JSON.parse(auth).expires) > new Date()

      if (!tokenAlreadyAquired) {
        const aquireToken = async () => {
          const myTokenRequest = {
            scopes: ["api://48fb5cd7-6d22-489c-8f60-20a868454d3c/SaveUp.User.Access"],
            account: instance.getActiveAccount(),
            forceRefresh: false // Optional
          }
          await instance
            .acquireTokenSilent({
              ...myTokenRequest,
              account: activeAccount,
            })
            .then((tokenResponse) => {
              const expiresOn = new Date(tokenResponse.expiresOn ?? new Date())
              const enumArray = Object.entries(AADRole)
                .filter(([key, value]) => typeof value === 'number')
                .map(([key, value]) => ({name: key, value}))
              const groups = tokenResponse.account?.idTokenClaims !== undefined ? (tokenResponse.account?.idTokenClaims['groups'] as string[]) : []
              const relevantRoles = enumArray.filter((enumValue) => groups.includes(enumValue.name))
              const role = relevantRoles.reduce((prev, current) => (prev.value > current.value ? prev : current)).name

              const auth = {
                id: parseInt(tokenResponse.uniqueId),
                isLoggedIn: tokenResponse.account !== null,
                refreshToken: '',
                expires: expiresOn,
                role: role,
                token: tokenResponse.accessToken,
                userName: tokenResponse.account?.username,
                isAAD: true,
              }
              localStorage.removeItem('auth')
              localStorage.setItem('auth', JSON.stringify(auth))
              navigate('/home')
            })
            .catch((error) => {
              console.log(error)
            })
        }
        aquireToken()
      } else {
        navigate('/home')
      }
    }
  }, [inProgress, isAuthenticated, accounts, instance, navigate, useAzureAuth])

  const onSubmit: SubmitHandler<ILogin> = async (data) => {
    setIsDisabled(true)
    setUseAzureAuth(false)
    dispatch(login(data))
    setTimeout(async () => {
      setAttempts(attempts + 1)
      setIsDisabled(false)
    }, attempts * 1000)
  }

  const onAzureLogin = async () => {
    setIsDisabled(true)
    try {      
      await instance.loginRedirect(loginRequest)
    } catch (err) {
      console.error(err)
      setIsDisabled(false) // Re-enable the button if there's an error
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField margin='normal' required fullWidth label='Användarnamn' {...register('username', {required: true})} />
      <TextField margin='normal' required fullWidth label='Lösenord' type='password' {...register('password', {required: true})} />
      <Button type='submit' fullWidth variant='contained' sx={{mt: 3, mb: 2}} disabled={isDisabled} style={{backgroundColor: '#00d170'}}>
        Logga in
      </Button>
      <Button
        fullWidth
        variant='contained'
        sx={{mt: 3, mb: 2}}
        disabled={isDisabled}
        style={{backgroundColor: '#007FFF', color: 'white'}}
        startIcon={<CloudCircle fontSize='small' />}
        onClick={onAzureLogin}>
        Logga in med Microsoft Entra ID
      </Button>
    </form>
  )
}
export default LoginForm
