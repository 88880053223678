import { LogLevel } from "@azure/msal-browser";
export const msalConfig = {
    auth: {
      clientId: "516cc51e-6526-4b6b-9d1d-234b663bf2b6", //"48fb5cd7-6d22-489c-8f60-20a868454d3c", // This is your client ID from Azure AD
      authority: `https://login.microsoftonline.com/a73d6e50-1476-4ed4-ab02-908e2bbfdbdb`, //d1914b7e-ab97-42ac-8cc3-5c95b32a8a08,
      redirectUri: "http://localhost:3000/login",
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              break;
            case LogLevel.Info:
              console.info(message);
              break;
            case LogLevel.Verbose:
              console.debug(message);
              break;
            case LogLevel.Warning:
              console.warn(message);
              break;
          }
        },
        loggerlevel: 'Verbose'
      },
    }
  };
  
  export const loginRequest = {
    scopes: ["User.Read",  "api://516cc51e-6526-4b6b-9d1d-234b663bf2b6/SaveUp.User.Access"] // api://48fb5cd7-6d22-489c-8f60-20a868454d3c/SaveUp.User.Access"] //, "GroupMember.Read.All"] // Add the scopes you need
  };

  export const tokenRequest = {
    scopes: ["api://516cc51e-6526-4b6b-9d1d-234b663bf2b6/SaveUp.User.Access"] //["api://48fb5cd7-6d22-489c-8f60-20a868454d3c/SaveUp.User.Access"] //, "GroupMember.Read.All"] // Add the scopes you need
  }