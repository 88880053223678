import {LOGGING_IN, LOGOUT, LOGIN_SUCCEDED, LOGIN_FAILED, LOGIN_UNAUTHORIZED} from './loginTypes'
import {IAuth, ILogin} from './loginInterfaces'
import {Dispatch} from 'redux'
import userService from '../../services/userService'
import {Role} from '../../shared/enums/Role'
import jwt_decode from 'jwt-decode'
export const login = (formValues: ILogin) => async (dispatch: Dispatch) => {
  dispatch({type: LOGGING_IN})
  try {
    const response: any = await userService.login(formValues)

    if (response) {
      const user: IAuth = {...response?.data, isAAD: false }
      if (user && Role[user.role as keyof typeof Role] >= Role.Customer) {
        localStorage.setItem('auth', JSON.stringify(user))
        return dispatch({type: LOGIN_SUCCEDED, payload: {auth: user, status: response.status}})
      } else if (user && Role[user.role as keyof typeof Role] < Role.Customer) {
        return dispatch({type: LOGIN_UNAUTHORIZED, payload: {auth: user, status: 403}})
      } else {
        return dispatch({type: LOGIN_UNAUTHORIZED, payload: {auth: undefined, status: response.status}})
      }
    }
    else {
      return dispatch({type: LOGIN_FAILED, payload: 'Tjänsten är ej tillgänglig för närvarande.'})
    }
  } catch (error) {
    return dispatch({type: LOGIN_FAILED, payload: error})
  }
}

export const logout = (dispatch: Dispatch) => {
  userService.logout()
  return dispatch({type: LOGOUT})
}
