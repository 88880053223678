import React, {useCallback, useEffect, useState} from 'react'
import {UserListProps} from './interfaces'
import {UserListItem} from '../../redux/user/interfaces'
import {IconButton, styled} from '@mui/material'
import {Delete, Edit, Restore} from '@mui/icons-material'
import {DataGrid, GridColDef, GridColumnHeaderParams, GridRenderCellParams, GridSortDirection} from '@mui/x-data-grid'
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../redux/rootReducer'
import {setListColumnFilter, setListColumnSorting} from '../../redux/filter/actions'
import {GridFilterModel, GridSortModel} from '@mui/x-data-grid'
import { debounce, delay } from 'lodash'

const UserList: React.FC<UserListProps> = (props: UserListProps) => {
  const dispatch = useDispatch()

  const filter = useSelector((state: RootState) => state.filter.listFilters[props.name])
  const [sortModel, setSortModel] = useState<GridSortModel>([])
  const [filterModel, setFilterModel] = useState<GridFilterModel>({items: []})

  const [users, setUsers] = useState<UserListItem[]>([])

  useEffect(() => {
    console.log(props.users)
    setUsers(props.users)
  }, [props.users])

  // Filter model for DataGrid as state variable

  useEffect(() => {
    // Update the sorting model for the datagrid by the homeListSorting
    if (filter && filter.sorting) {
      const sortModel: GridSortModel = []
      if (filter.sorting.columnName) {
        sortModel.push({field: filter.sorting.columnName, sort: (filter.sorting.order === 'asc' ? 'asc' : 'desc') as GridSortDirection})
      }
      setSortModel(sortModel)
    }
    if (filter && filter.model) {
      setFilterModel(filter.model)
    }
  }, [])

  const userTableColumnHeaders: GridColDef[] = [
    {field: 'fullName', headerName: 'Namn', width: 200},
    {field: 'mail', headerName: 'Användarnamn', flex: 1},
    {field: 'role', headerName: 'Roll', width: 100},
    {field: 'updDate', headerName: 'Uppdaterades', width: 220},
    {field: 'insDate', headerName: 'Skapades', width: 200},
    {field: 'id', headerName: 'ID', width: 80},
    {field: "userPrincipalName", headerName:"User Principal Name", width: 200},
    {field: "isAADEnabled", headerName:"Entra ID Synk", width: 80, renderCell: (params: GridRenderCellParams<boolean>) => {
      return  params.row.isAADEnabled ? 'Ja' : 'Nej'
    }},
    {
      width: 100,
      field: 'id_icons',
      headerName: 'Ikoner',
      renderHeader: (params: GridColumnHeaderParams) => <div></div>,
      renderCell: (params: GridRenderCellParams<boolean>) => {
        if (props.hideButtons) {
          return (
            <div>
              {' '}
              <IconButton onClick={() => props.onDisableClick(params.row.id, params.row.mail, false)}>
                <Restore fontSize='small' />
              </IconButton>
            </div>
          )
        } else {
          return (
            <div style={{display: 'flex', gap: '10px'}}>
              <Link to={`/user/${params.row.id}/edit`}>
                <IconButton>
                  <Edit fontSize='small' />
                </IconButton>
              </Link>
              <IconButton onClick={() => props.onDisableClick(params.row.id, params.row.mail, true)}>
                <Delete fontSize='small' />
              </IconButton>
            </div>
          )
        }
      },
    },
  ]
  const handleSortModelChange = (newModel: GridSortModel) => {
    if (newModel.length > 0) {
      setSortModel(newModel)
      const { field, sort } = newModel[0]
      dispatch(setListColumnSorting(props.name, field, sort === 'asc' ? 'asc' : 'desc'))
    }
  }

  const handleFilterModelChange = (newModel: GridFilterModel) => {
    setFilterModel(newModel)
    dispatch(setListColumnFilter(props.name, newModel))
  }
  
  return (
    <div>
      {props.isLoading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <DataGrid
            pageSize={100}
            rows={users ?? []}
            columns={userTableColumnHeaders}
            autoHeight
            getRowHeight={() => 'auto'}
            style={props.hideButtons ? {'width': '100%', fontStyle: 'italic'} : {'width': '100%'}}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={(model: any) => handleFilterModelChange(model)}
            filterModel={filterModel}
            sortModel={sortModel}
          />
        </div>
      )}
    </div>
  )
}
export default UserList
